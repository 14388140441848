import React from 'react';
import PropTypes from 'prop-types';
import * as CustomPropTypes from 'lib/CustomPropTypes';
import { REORDER_PLAYLIST_SO_NEXT_VIDEO_IS_ALWAYS_AT_TOP } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import classNames from 'classnames';
import { forceCheck } from 'react-lazyload';

import Video from '../Video';

import styles from './styles.module.scss';

export const LAYOUT = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

class VideoDrawerPlaylist extends React.Component {
  static propTypes = {
    trackLink: PropTypes.func,
    playlist: CustomPropTypes.videoPlaylist.isRequired,
    layout: PropTypes.oneOf(Object.values(LAYOUT)),
    nowPlaying: PropTypes.string,
    onSelect: PropTypes.func,
    allowCollapse: PropTypes.bool,
    collapsedByDefault: PropTypes.bool,
    vertical: PropTypes.string,
  };

  static defaultProps = {
    layout: LAYOUT.VERTICAL,
    nowPlaying: null,
    onSelect: Function.prototype,
    trackLink: () => {},
    allowCollapse: false,
    collapsedByDefault: false,
    vertical: null,
  };

  constructor(props) {
    super(props);

    const collapsedByDefault = props.allowCollapse && props.collapsedByDefault;

    this.state = {
      collapsed: collapsedByDefault,
    };
  }

  componentDidUpdate() {
    const { vertical } = this.props;
    if (getFeatureConfigForBrand(REORDER_PLAYLIST_SO_NEXT_VIDEO_IS_ALWAYS_AT_TOP, vertical)) {
      // Check lazyloaded elements that may have entered the viewport when playlist gets reordered
      forceCheck();
    }
  }

  toggle = () => {
    const { allowCollapse } = this.props;

    if (!allowCollapse) {
      return;
    }

    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  }

  /**
 * @param {object} playlist
 * @param {number} index
 * @param {object} prevVideo This is the previous video object in the playlist
 * @param {string} nowPlaying This is the ID of the video currently playing
 * @param {object} currVideo
 *  This is the video object of the current video in the iteration of the playlist
 */
  upNext = ({
    playlist, index, prevVideo, nowPlaying, currVideo,
  }) => {
    if (playlist) {
      if (prevVideo && prevVideo.id === nowPlaying) return true;
      if (
        index === 0
        && currVideo.id !== nowPlaying
        && Array.isArray(playlist.videos)
        // playlist doesn't include currently playing video
        && !playlist.videos.some((v) => v.id === nowPlaying)
      ) {
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      playlist, nowPlaying, onSelect, layout, allowCollapse, vertical, trackLink,
    } = this.props;
    const videos = (playlist?.videos) ? playlist.videos : null;

    if (videos) {
      if (getFeatureConfigForBrand(REORDER_PLAYLIST_SO_NEXT_VIDEO_IS_ALWAYS_AT_TOP, vertical)) {
        const currentVideoIndex = videos.findIndex((video) => video.id === nowPlaying);
        if (currentVideoIndex > -1) {
          // Reorder playlist so current video is at the beginning
          videos.unshift(...videos.splice(currentVideoIndex));
        }
      }
    }

    const { collapsed } = this.state;

    const className = classNames(styles.playlist, styles[layout], {
      [styles.collapsed]: collapsed,
      [styles.collapsible]: allowCollapse,
      [styles.recommendations]: playlist?.recommendations,
    });

    return (
      <section className={className}>
        {videos && (
          <ul className={styles.videos}>
            {videos.map((video, index) => (
              <li key={video.mpxMetadata.id || video.id} className={styles.video}>
                <Video
                  video={video}
                  onClick={(...args) => {
                    trackLink({
                      action: 'videoClick',
                      id: video.id,
                      title: video.headline?.primary,
                    });
                    onSelect(...args);
                  }}
                  playlistIndex={index}
                  playlist={playlist}
                  selected={video.id === nowPlaying}
                  upNext={this.upNext({
                    playlist, index, prevVideo: videos[index - 1], currVideo: video, nowPlaying,
                  })}
                />
              </li>
            ))}
          </ul>
        )}
      </section>
    );
  }
}

export default VideoDrawerPlaylist;
