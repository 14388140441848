const { getDomain } = require('tldjs');

const { NODE_PORT } = process.env;
const DEFAULT_DOMAIN = 'nbcnews.com';

module.exports = (host, { warn = false } = {}) => {
  const parsedDomain = getDomain(host);

  if (parsedDomain) {
    return parsedDomain;
  }

  if (warn) {
    // eslint-disable-next-line
    console.error(`
      NOTICE:

      You are entering Ramen through ${host} which is no longer supported.
      For legacy support Ramen has set your host to *.nbcnews.com.

      Please update your /etc/hosts to include the following:
        127.0.0.1 localhost.nbcnews.com
        127.0.0.1 localhost.today.com
        127.0.0.1 localhost.msnbc.com

      Ramen should now be accessible at:
        http://localhost.nbcnews.com:${NODE_PORT}/
        http://localhost.today.com:${NODE_PORT}/
        http://localhost.msnbc.com:${NODE_PORT}/
    `);
  }

  return DEFAULT_DOMAIN;
};
