import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18next from 'i18next';

import * as CustomPropTypes from 'lib/CustomPropTypes';
import { VerticalContext } from 'lib/ContextTypes';
import { formatDuration } from 'lib/DateTime';
import { imageAltForImage } from 'lib/imageAlt';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { REMOVE_NOW_PLAYING_VIDEO_FROM_PLAYLIST } from 'lib/brandFeatures';
import { isVideoLive } from 'lib/videoUtils';
import { InlineBlinkingLiveDot } from 'components/InlineBlinkingLiveDot';
import { Picture } from 'components/Picture';

import './styles.themed.scss';

class Video extends React.Component {
  static contextType = VerticalContext;

  static propTypes = {
    video: CustomPropTypes.video.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    playlistIndex: PropTypes.number,
    playlist: CustomPropTypes.videoPlaylist,
    upNext: PropTypes.bool,
  };

  static defaultProps = {
    selected: false,
    onClick: Function.prototype,
    playlistIndex: 0,
    playlist: {},
    upNext: false,
  };

  clickHandler = (event) => {
    event.preventDefault();
    const {
      video, onClick, playlistIndex, playlist,
    } = this.props;
    onClick(video, playlistIndex, playlist, 'manual');
    window.scrollTo(0, 0);
  }

  render() {
    const vertical = this.context;
    const {
      video,
      selected,
      upNext,
    } = this.props;
    const { primaryImage, duration, headline } = video;
    const isLiveVideo = isVideoLive(video);

    if (getFeatureConfigForBrand(REMOVE_NOW_PLAYING_VIDEO_FROM_PLAYLIST, vertical) && selected) {
      return null;
    }

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return (
      <div
        className="playlist-drawer__video"
        onClick={this.clickHandler}
        data-testid="playlist-drawer-video"
      >
        {upNext && <div className="playlist-drawer__up-next">{i18next.t('UP NEXT')}</div>}
        <div
          className="playlist-drawer__thumbnail"
        >
          {selected && <div className="playlist-drawer__active" data-testid="playlist-active">{i18next.t('Now Playing')}</div>}
          {isLiveVideo
            && (
              <span className="playlist-drawer__flag">
                <InlineBlinkingLiveDot className="playlist-drawer__flag-dot" />
                {i18next.t('Live')}
              </span>
            )}
          <Picture
            url={primaryImage.url.primary}
            className={classNames('playlist-drawer__thumbnail')}
            responsiveFlavors={{
              s: AIMS_FLAVORS.FOCAL_520x260,
              m: AIMS_FLAVORS.FOCAL_760x380,
              l: AIMS_FLAVORS.FOCAL_280x140,
            }}
            alt={imageAltForImage(primaryImage)}
            lazyloaded // Related thumbnails are never Largest Contentful Paint
          />
        </div>
        <div className={classNames('playlist-drawer__info', 'pl4-l pr6-l pa5-l')}>
          <h2 className={classNames('playlist-drawer__title')} data-testid="playlist-title">
            {headline.tease}
          </h2>
          <span className={classNames('playlist-drawer__duration', 'founders-mono')} data-testid="playlist-duration">
            {formatDuration(duration)}
          </span>
        </div>
      </div>
    );
    /* eslint-enable jsx-a11y/click-events-have-key-events */
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

export default Video;
