import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import getConfig from 'next/config';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { setPageView } from 'redux/modules/shared';
import {
  loadVideo as loadVideoAction,
  setCurrent as setCurrentAction,
} from 'redux/modules/video';
import { loadRelatedArticle as loadRelatedArticleAction } from 'redux/modules/video/relatedArticle';
import { loadRelatedVideo as loadRelatedVideoAction } from 'redux/modules/video/relatedVideo';
import { loadTopPlaylist } from 'redux/modules/video/topPlaylists';
import {
  loadAssociatedPlaylist as loadAssociatedPlaylistAction,
  loadVideosByIds as loadVideosByIdsAction,
} from 'redux/modules/video/associatedPlaylists';

import {
  MOBILE_PLAYLIST_NUMBER_OF_VIDEOS,
  MOBILE_CAROUSEL_DRAWER,
  HAS_BACON_VIDEO_PLAYLISTS,
} from 'lib/brandFeatures';
import { FeatureFlagContext } from 'lib/ContextTypes';

import BTE from 'lib/BTE';
import buildUrl from 'lib/buildUrl';
import {
  relatedVideoArticle as relatedVideoArticlePropType,
  socialMediaProfiles as socialMediaProfilePropType,
  video as videoPropType,
  videoPlaylist as videoPlaylistPropType,
} from 'lib/CustomPropTypes';
import { layout as servicesLayoutPropType } from 'lib/CustomPropTypes/services';
import determineDomain from 'lib/determineDomain';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import isVerticalVideo from 'lib/isVerticalVideo';
import MobileDetection from 'lib/MobileDetection';
import { navbar, NAVBAR_THEME } from 'lib/navbar';
import { parseIdFromUrl } from 'lib/parseIdFromUrl';
import parseQueryString from 'lib/parseQuerystring';
import { stub as $t } from '@nbcnews/analytics-framework';
import { parseOnClient } from 'lib/urlUtils';
import { getAutoplayTestGroup, AUTOPLAY_TEST_GROUPS } from 'lib/videoUtils';
import { setLinkHeaders } from 'lib/setLinkHeaders';
import { formatPlaylistDataForBacon } from 'lib/baconHelpers';

import AdsBundle from 'components/AdsBundle';
import { ThemesCssBundle } from 'components/ThemesCssBundle';
import { AnalyticsLaunchScripts } from 'components/AnalyticsLaunchScripts';
import BestOfPlaylist from 'components/BestOfPlaylist';
import { Bacon } from 'components/packages/Bacon';
import { ErrorBoundary } from 'components/ErrorBoundary';
import PlaylistDrawer, { LAYOUT } from 'components/PlaylistDrawer';
import Playlister from 'components/Playlister';
import { HeaderAndFooter } from 'components/services/HeaderAndFooter';
import { GlobalMetadata, VideoMetadata } from 'components/PageMetadata';
import ScrollingAnalytics from 'components/ScrollingAnalytics';
import Taboola from 'components/Taboola';
import VideoDetails from 'components/VideoDetails';
import VideoPlayer from 'components/VideoPlayer';
import Ad from 'components/Ad';
import VideoAutoplayCapabilitiesDetector, {
  NoDetect,
} from 'components/NdpVideoPlayer/AutoplayCapabilitiesDetector';
import IconfontStyleTag from 'components/IconfontStyleTag';
import UniversalCheckout from 'components/UniversalCheckout';
import { CommerceAmazonContentInsights } from 'components/Commerce/AmazonContentInsights';

import { PrimaryMediaBanner } from 'components/PrimaryMediaBanner';
import { logAction, logError } from 'lib/datadog';
import ErrorPage from '../_error';

import 'assets/styles/main.scss';
import 'assets/styles/toolkit.scss';
import './styles.themed.scss';
import globalContainerStyles from '../globalContainerStyles.module.scss';

const pageView = 'video';

const {
  publicRuntimeConfig: {
    ENABLE_AUTOPLAY_TEST,
  },
  publicRuntimeConfig,
} = getConfig();

export const sortArrayByList = (order, property) => (a, b) => order.indexOf(a[property]) - order.indexOf(b[property]);

/**
 * Limits the number of videos in each top playlist
 * @param {object} topPlaylists
 * @returns topPlaylists with limited number of videos
 */
export function limitSizeOfTopPlaylists({ topPlaylists, limit }) {
  if (!Number.isInteger(limit)) return topPlaylists;
  return topPlaylists.map((playlist) => ({
    ...playlist,
    videos: playlist.videos.slice(0, limit),
  }));
}

const brandsWithTaboolaFeed = ['news', 'today', 'msnbc', 'mach', 'better', 'think', 'noticias'];

export const VERT_TOP_PLAYLISTS = {
  today: ['mmlsnnd_55339009-nnd', 'mmlsnnd_today3rdhour-nnd', 'mmlsnnd_26316687-nnd', 'mmlsnnd_shoptoday', 'mmlsnnd_34846743-nnd'],
  news: ['mmlsnnd_bestofnbc'],
  mach: ['mmlsnnd_mach'],
  better: ['mmlsnnd_better'],
  think: ['mmlsnnd_think'],
  msnbc: ['mmlsnnd_3096433-nnd'],
};

export const TITLE_FOR_PLAYLIST_ID = {
  'mmlsnnd_55339009-nnd': 'Latest Videos',
  'mmlsnnd_today3rdhour-nnd': '3rd Hour of TODAY',
  'mmlsnnd_26316687-nnd': 'TODAY with Hoda & Jenna',
  mmlsnnd_shoptoday: 'Shop the Show',
  'mmlsnnd_34846743-nnd': 'Recipes from the Show',
};

export const navbarConfig = {
  theme: ({ content, vertical }) => {
    if (content.current?.error) {
      switch (vertical) {
        case 'today':
          return NAVBAR_THEME.LIGHT;
        default:
          return NAVBAR_THEME.VERTICAL;
      }
    }

    switch (vertical) {
      case 'telemundo':
      case 'entretenimiento':
      case 'shows':
        return NAVBAR_THEME.VERTICAL;
      default:
        return NAVBAR_THEME.DARK;
    }
  },
};

const mapStateToProps = ({
  video,
  shared,
}) => ({
  associatedPlaylists: video.associatedPlaylists,
  isChromeless: shared.isChromeless,
  nowPlaying: video.current,
  relatedArticle: video.relatedArticle,
  relatedVideo: video.relatedVideo,
  socialMediaProfiles: shared.socialMediaProfiles,
  topPlaylists: limitSizeOfTopPlaylists({ topPlaylists: video.topPlaylists, limit: 10 }),
});

const mapActionsToProps = (dispatch) => ({
  loadVideo: (video, vertical) => dispatch(loadVideoAction(video, vertical)),
  loadAssociatedPlaylist: (playlistID) => dispatch(loadAssociatedPlaylistAction(playlistID)),
  loadVideosByIds: (ids) => dispatch(loadVideosByIdsAction(ids)),
  loadRelatedArticle: (id) => dispatch(loadRelatedArticleAction(id)),
  loadRelatedVideo: (id) => dispatch(loadRelatedVideoAction(id)),
  setCurrent: (video) => dispatch(setCurrentAction(video)),
});

$t('register', 'mbt_video_drawer_click');
class VideoPage extends React.Component {
  static contextType = FeatureFlagContext;

  static getInitialProps = async (ctx) => {
    const {
      req: {
        params: {
          id,
          playlistId,
        },
      },
      res: {
        locals: {
          vertical,
        },
      },
      store,
    } = ctx;

    const promises = [
      store.dispatch(loadVideoAction(id, vertical)),
      store.dispatch(setPageView(pageView)),
    ];
    // Load playlist for BestOfPlaylist component below player
    const vertTopPlaylists = VERT_TOP_PLAYLISTS[vertical];

    if (vertTopPlaylists) {
      vertTopPlaylists.forEach((topPlaylistId) => {
        promises.push(store.dispatch(loadTopPlaylist(topPlaylistId)));
      });
    }
    // View playlist
    if (playlistId) {
      promises.push(
        store.dispatch(loadAssociatedPlaylistAction(playlistId)),
      );
    }

    await Promise.all(promises);

    const videoStateAfterLoad = store.getState().video;
    const statusCode = videoStateAfterLoad?.current?.error?.status ?? 200;

    setLinkHeaders(ctx.res, vertical);

    return {
      navigating: false,
      pageView,
      statusCode,
    };
  };

  static propTypes = {
    associatedPlaylists: PropTypes.arrayOf(videoPlaylistPropType),
    isChromeless: PropTypes.bool.isRequired,
    layout: servicesLayoutPropType.isRequired,
    loadAssociatedPlaylist: PropTypes.func,
    loadRelatedArticle: PropTypes.func,
    loadRelatedVideo: PropTypes.func,
    loadVideo: PropTypes.func.isRequired,
    loadVideosByIds: PropTypes.func,
    nowPlaying: videoPropType.isRequired,
    relatedArticle: relatedVideoArticlePropType.isRequired,
    relatedVideo: relatedVideoArticlePropType.isRequired,
    setCurrent: PropTypes.func.isRequired,
    socialMediaProfiles: socialMediaProfilePropType.isRequired,
    statusCode: PropTypes.number.isRequired,
    topPlaylists: PropTypes.arrayOf(videoPlaylistPropType),
    vertical: PropTypes.string.isRequired,
  };

  static defaultProps = {
    associatedPlaylists: [],
    loadAssociatedPlaylist: Function.prototype,
    loadRelatedArticle: Function.prototype,
    loadRelatedVideo: Function.prototype,
    loadVideosByIds: Function.prototype,
    topPlaylists: [],
  };

  constructor(props) {
    super(props);

    const PLATFORM = MobileDetection.any() ? 'MOBILE' : 'DESKTOP';
    /* eslint-disable max-len */
    const AUTOPLAY_TEST_ANY_PERCENTAGE = publicRuntimeConfig[`AUTOPLAY_TEST_${PLATFORM}_ANY_PERCENTAGE`];
    const AUTOPLAY_TEST_UNMUTED_PERCENTAGE = publicRuntimeConfig[`AUTOPLAY_TEST_${PLATFORM}_UNMUTED_PERCENTAGE`];
    const AUTOPLAY_TEST_DISABLED_PERCENTAGE = publicRuntimeConfig[`AUTOPLAY_TEST_${PLATFORM}_DISABLED_PERCENTAGE`];
    /* eslint-enable max-len */

    const autoplayTestGroup = getAutoplayTestGroup({
      AUTOPLAY_TEST_ANY_PERCENTAGE: parseInt(AUTOPLAY_TEST_ANY_PERCENTAGE, 10),
      AUTOPLAY_TEST_UNMUTED_PERCENTAGE: parseInt(AUTOPLAY_TEST_UNMUTED_PERCENTAGE, 10),
      AUTOPLAY_TEST_DISABLED_PERCENTAGE: parseInt(AUTOPLAY_TEST_DISABLED_PERCENTAGE, 10),
    });

    this.state = {
      fetchPlaylistData: false,
      enforceRatio: false,
      nowPlayingInitiator: 'continuous',
      autoplayTestGroup,
    };
  }

  componentDidMount() {
    const {
      statusCode,
      vertical,
    } = this.props;

    if (statusCode === 200) {
      this.updatePlaylistDrawer();
      this.fetchRelatedArticle();

      const hasMobileCarouselDrawer = getFeatureConfigForBrand(MOBILE_CAROUSEL_DRAWER, vertical);

      if (!hasMobileCarouselDrawer) {
        BTE.monitorResize();

        BTE.on('resize', this.onResize, 'debounce');
        this.onResize({
          width: window.innerWidth || document.documentElement.clientWidth,
          height: window.innerHeight || document.documentElement.clientHeight,
        });
      }
    }
  }

  onResize = ({ width: ww, height: wh }) => {
    const { enforceRatio } = this.state;
    const {
      top,
      width: pw,
      height: ph,
    } = (this.playerWrapper && this.playerWrapper.getBoundingClientRect()) || {};

    if (top + ph > wh) {
      if (!enforceRatio) {
        // TODO (related to BENTO-10896): figure out what race condition (probably)
        // is causing `this.drawerWrapper` to be undefined sometimes when this method
        // is called. Adding a check around `this.playerWrapper` as well, to be safe
        if (this.playerWrapper && typeof this.playerWrapper.setAttribute === 'function') {
          this.playerWrapper.setAttribute(
            'style',
            `flex-grow:unset; margin:0 auto; width:calc((100vh - ${top}px)*(16/9)); height:calc(100vh - ${top}px)`,
          );
        }
        if (this.drawerWrapper && typeof this.drawerWrapper.setAttribute === 'function') {
          this.drawerWrapper.setAttribute('style', `height:calc(100vh - ${top}px);`);
        }
        this.setState({ enforceRatio: true });
      }
    } else if ((ww < 1000 && ww === pw) || (ww >= 1000 && ww <= pw + 465)) {
      if (enforceRatio) {
        if (this.playerWrapper && typeof this.playerWrapper.removeAttribute === 'function') {
          this.playerWrapper.removeAttribute('style');
        }
        if (this.drawerWrapper && typeof this.drawerWrapper.removeAttribute === 'function') {
          this.drawerWrapper.removeAttribute('style');
        }
        this.setState({ enforceRatio: false });
      }
    }
    logAction('video', 'videoResized');
  };

  onVideoPlay = () => {
    const { fetchPlaylistData } = this.state;
    const {
      associatedPlaylists,
      loadVideosByIds,
    } = this.props;

    if (fetchPlaylistData) {
      const ids = associatedPlaylists[0].videos.map((video) => video.mpxMetadata.id);

      // Send request for Video-teases of multiple ids.
      loadVideosByIds(ids);

      this.setState({
        fetchPlaylistData: false,
      });
    }
    logAction('video', 'videoPlayed');
  };

  onVideoSelect = async (selected, playlistIndex, playlist, initiator) => {
    // Track at beginning, so it fires in both cases.
    $t('track', 'mbt_video_drawer_click', { video: selected, index: playlistIndex, playlist });
    logAction('videoSelected', { video: selected, index: playlistIndex, playlist });

    this.setState({ nowPlayingInitiator: initiator });

    if (
      !isVerticalVideo(selected)
      && !determineDomain(selected.url.primary).match(
        /(nbcnews|today|msnbc|telemundo).com/i,
      )
    ) {
      window.location = selected.url.primary;
      return;
    }

    let url;
    const {
      loadVideo, nowPlaying, setCurrent, vertical,
    } = this.props;

    if (!selected.id) {
      await loadVideo(selected.mpxMetadata.id, vertical);
      url = buildUrl(nowPlaying);
    } else {
      await setCurrent(selected);
      url = buildUrl(selected);
    }

    /*
     * Instead of routing, we'll update url for sharing.
     * Users most likely don't see this page as a seperate
     * pages and back button functionality will throw them off
     * so we replace state instead of push.
     */
    const parsedURL = parseOnClient(url);
    const normalizedURL = parsedURL.pathname || url;

    /**
     * We maintain the video page query parameters across
     * clicking through the playlists.
     */
    const { search: currentPageQueryParametersString } = window.location;
    const replacementURL = `${normalizedURL}${currentPageQueryParametersString}`;
    window.history.replaceState(null, null, replacementURL);
  };

  showTaboola = () => {
    const { vertical } = this.props;
    if (brandsWithTaboolaFeed.includes(vertical)) {
      return <Taboola />;
    }
    return null;
  };

  fetchRelatedArticle = () => {
    const {
      associatedPlaylists,
      loadRelatedArticle,
      loadRelatedVideo,
      nowPlaying,
    } = this.props;

    const relatedContentLink = nowPlaying?.relatedContentLink ?? { text: null, url: null };
    const relatedURL = relatedContentLink.url || associatedPlaylists[0].relatedUrl;
    if (!relatedURL) {
      return;
    }
    const relatedArticleId = parseIdFromUrl(relatedURL);
    if (!relatedArticleId) {
      return;
    }
    if (relatedArticleId.indexOf('mmvo') === 0 || relatedArticleId.indexOf('tmvo') === 0) {
      loadRelatedVideo(relatedArticleId);
    } else {
      loadRelatedArticle(relatedArticleId);
    }
  };

  updatePlaylistDrawer = () => {
    const {
      loadAssociatedPlaylist,
    } = this.props;

    // Playlist in path
    const queryParams = parseQueryString(window.location.search);
    if (queryParams?.playlist) {
      loadAssociatedPlaylist(queryParams.playlist);
    }
  };

  drawer = () => {
    const { associatedPlaylists, nowPlaying, vertical } = this.props;

    return (
      <div
        className="video-page__drawer"
        ref={(element) => { this.drawerWrapper = element; }}
      >
        <PlaylistDrawer
          playlists={associatedPlaylists}
          nowPlaying={nowPlaying.id}
          layout={LAYOUT.VERTICAL}
          onSelect={this.onVideoSelect}
          vertical={vertical}
        />
      </div>
    );
  };

  bestOf = () => {
    const { vertical, topPlaylists } = this.props;
    const showBestOf = vertical.match(/news|msnbc|today|mach|think|better/i);

    if (!showBestOf || !topPlaylists || !topPlaylists[0]) {
      return null;
    }

    if (vertical.match(/today|mach|think|better/i)) {
      topPlaylists[0].headline.primary = ['Best of ', `${vertical.toUpperCase()}`];
    }

    if (vertical === 'msnbc') {
      topPlaylists[0].headline.primary = topPlaylists[0].headline.primary.toUpperCase();
    }

    return (
      <BestOfPlaylist
        playlist={topPlaylists[0]}
        title={topPlaylists[0].headline.primary}
        vertical={vertical}
      />
    );
  };

  bacon = () => {
    const { vertical, topPlaylists } = this.props;

    return topPlaylists.sort(sortArrayByList(VERT_TOP_PLAYLISTS[vertical], 'id')).map((playlist) => {
      const playlistTitle = TITLE_FOR_PLAYLIST_ID[playlist.id];
      const overwrites = playlistTitle ? { metadata: { title: playlistTitle } } : {};

      return (
        <Bacon
          key={playlist.id}
          content={formatPlaylistDataForBacon(playlist, overwrites)}
          vertical={vertical}
        />
      );
    });
  };

  insertAd = (arr, index) => {
    arr.splice(index, 0, (
      <div className="dn db-m">
        <Ad
          slot="topbanner"
          offsetViewport={100}
          pkgClassName="pkg-ad ad ad--centered"
          refreshInterval={0}
        />
      </div>));
    return arr;
  };


  handleMainContentError = () => (
    <ErrorPage
      statusCode={500}
    />
  );

  computeMobilePlaylist(playlist) {
    const { vertical } = this.props;
    const numberOfVideos = getFeatureConfigForBrand(MOBILE_PLAYLIST_NUMBER_OF_VIDEOS, vertical);
    const hasMobileCarouselDrawer = getFeatureConfigForBrand(MOBILE_CAROUSEL_DRAWER, vertical);

    if (!(playlist?.videos)) return { ...playlist, videos: null };
    return {
      ...playlist,
      videos: hasMobileCarouselDrawer ? playlist.videos : playlist.videos.slice(0, numberOfVideos),
    };
  }

  render() {
    const {
      associatedPlaylists,
      isChromeless,
      layout,
      nowPlaying,
      relatedArticle,
      relatedVideo,
      socialMediaProfiles,
      statusCode,
      topPlaylists,
      vertical,
    } = this.props;

    const { 'cta-canonical-video-page': primaryMediaBannerEnabled } = this.context;

    if (parseInt(statusCode, 10) >= 400) {
      return (
        <ErrorPage
          statusCode={statusCode === 404 ? 404 : 500}
          vertical={vertical}
          layout={layout}
        />
      );
    }

    const { autoplayTestGroup, nowPlayingInitiator } = this.state;

    let relatedInfo = null;
    if (relatedVideo?.headline?.primary) {
      relatedInfo = { ...relatedVideo };
    } else {
      relatedInfo = { ...relatedArticle };
    }

    const relatedMPXLinkText = nowPlaying?.relatedContentLink?.text ?? null;
    const relatedMPXLinkURL = nowPlaying?.relatedContentLink?.url ?? null;
    const originalHeadline = relatedInfo?.headline ?? { primary: null };

    relatedInfo.description = relatedInfo?.description ?? null;
    relatedInfo.ecommerceEnabled = relatedInfo?.ecommerceEnabled ?? false;
    relatedInfo.headline = originalHeadline;
    relatedInfo.url = null;

    if (relatedMPXLinkText && relatedMPXLinkURL) {
      relatedInfo.headline.primary = relatedMPXLinkText;
      relatedInfo.url = relatedMPXLinkURL;
    }

    if (!relatedMPXLinkText && relatedInfo && relatedInfo.headline) {
      if (relatedInfo.headline.primary === null) {
        relatedInfo.headline = null;
      }
      relatedInfo.url = relatedMPXLinkURL;
    }

    const mobilePlaylist = [this.computeMobilePlaylist(associatedPlaylists[0])];
    const hasPlaylister = !vertical.match(/msnbc|news|today|mach|better|think/i);

    const isUserInAutoplayGroup = autoplayTestGroup !== AUTOPLAY_TEST_GROUPS.AUTOPLAY_DISABLED;
    const shouldCheckAutoplayCapabilities = ENABLE_AUTOPLAY_TEST && isUserInAutoplayGroup;
    const shouldShowBacon = getFeatureConfigForBrand(HAS_BACON_VIDEO_PLAYLISTS, vertical);
    const shouldShowCTA = primaryMediaBannerEnabled && vertical === 'news';
    const VideoPlayerWrapper = shouldCheckAutoplayCapabilities
      ? VideoAutoplayCapabilitiesDetector
      : NoDetect;

    const primaryMediaBanner = (
      <PrimaryMediaBanner
        type="newsNow"
        theme="dark"
        dataIcid="canonical-video_primary-media-banner-cta"
      />
    );

    return (
      <>
        <Head>
          <IconfontStyleTag />
        </Head>
        <ThemesCssBundle vertical={vertical} />
        <div
          className={classNames(
            globalContainerStyles.container,
            'bg-knockout-primary',
          )}
          id="content"
        >
          <HeaderAndFooter layout={layout}>
            <ErrorBoundary errorHandler={this.handleMainContentError} errorLogger={logError}>
              <ScrollingAnalytics contentType="video" contentUrl={nowPlaying.url.primary}>
                <GlobalMetadata />
                <VideoMetadata video={nowPlaying} vertical={vertical} />

                <div className="video-page" data-testid="video-page">
                  <div className="video-page__video">
                    <div className="video-page__main">
                      <div
                        className="video-page__player"
                        data-testid="video-page__player"
                        ref={(element) => { this.playerWrapper = element; }}
                      >
                        <VideoPlayerWrapper>
                          {({ ...autoplayCapabilities }) => (
                            <VideoPlayer
                              video={nowPlaying}
                              nowPlayingInitiator={nowPlayingInitiator}
                              autoPlay
                              stickyOffset={80}
                              continuousPlay
                              playlist={associatedPlaylists}
                              onAdPlay={this.onVideoPlay}
                              adPlayButtonPosition="bottomLeft"
                              onVideoPlay={this.onVideoPlay}
                              onVideoEnd={this.onVideoSelect}
                              autoplayCapabilities={autoplayCapabilities}
                              autoplayTestGroup={autoplayTestGroup}
                              isTestingAutoplayCapabilities={
                                (autoplayCapabilities || {}).isTestingAutoplayCapabilities
                              }
                              vertical={vertical}
                            />
                          )}
                        </VideoPlayerWrapper>
                      </div>
                      {this.drawer()}
                    </div>
                    <VideoDetails
                      vertical={vertical}
                      video={nowPlaying}
                      social={socialMediaProfiles}
                      relatedArticle={relatedInfo}
                      isChromeless={isChromeless}
                    />
                  </div>
                  {shouldShowCTA && (
                    <div className="video-page__CTA--mobile">
                      {primaryMediaBanner}
                    </div>
                  )}
                  <div className="video-page__carousel" data-testid="video-page-playlist">
                    <PlaylistDrawer
                      playlists={mobilePlaylist}
                      nowPlaying={nowPlaying.id}
                      layout={LAYOUT.VERTICAL}
                      onSelect={this.onVideoSelect}
                      vertical={vertical}
                    />
                  </div>

                  {hasPlaylister && <Playlister playlists={topPlaylists} />}

                  {shouldShowCTA && (
                    <div className="video-page__CTA--desktop">
                      {primaryMediaBanner}
                    </div>
                  )}
                  {shouldShowBacon ? this.insertAd(this.bacon(), 1) : this.bestOf()}
                  {this.showTaboola()}
                </div>
              </ScrollingAnalytics>
            </ErrorBoundary>
          </HeaderAndFooter>
        </div>

        <AnalyticsLaunchScripts />
        <AdsBundle />
        <UniversalCheckout vertical={vertical} />
        <CommerceAmazonContentInsights />
      </>
    );
  }
}

export default navbar(navbarConfig)(
  connect(mapStateToProps, mapActionsToProps)(VideoPage),
);

export { VideoPage as UnwrappedVideoPage };
