import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Unibrow } from 'components/Unibrow';
import Link from 'components/Link';

import {
  AUTHENTICATED,
  INITIALIZED,
  NEWS,
  UNAUTHENTICATED,
  UNINITIALIZED,
  UNKNOWN,
  VIDEO,
} from 'lib/myNewsConstants';

import { withStore, useMyNewsStore } from 'store';

import * as CustomPropTypes from 'lib/CustomPropTypes';

import { getFormattedDateString, formatDuration } from 'lib/DateTime';
import { getSlugForVertical } from 'lib/vertical';
import { withAccountWorkflows } from 'lib/Hooks/useAccountWorkflows';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import {
  UPDATED_CANONICAL_VIDEO, CANONICAL_VIDEO_SHARE_THIS, CANONICAL_VIDEO_SAVE,
} from 'lib/brandFeatures';
import { stub as $t } from '@nbcnews/analytics-framework';
import { isVideoLive, isSourcePartner } from 'lib/videoUtils';
import { InlineBlinkingLiveDot } from 'components/InlineBlinkingLiveDot';
import { SocialShareMenu } from 'components/SocialShareMenu';
import { BookmarkButton } from 'components/BookmarkButton';

import './styles.themed.scss';

const EVENT_NAME = 'mbt_video_socialShare';

$t('register', EVENT_NAME);

/**
 * VideoDetails
 * renders the video details:
 * title, description, related articles, social share options, sponsor information, and more.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.vertical - brand
 * @param {Object} props.video - The video object containing its details.
 * @param {Object} [props.relatedArticle] - related article obj
 * @param {boolean} props.isChromeless - flag for UI-less browser
 * @param {Function} props.saveHistory - func to save the video to history.
 * @param {string} [props.savedContentId] - The ID of the saved content.
 * @param {string} [props.mparticleId] - The mParticle ID.
 * @param {string} [props.authenticationState] - user's auth state
 *
 */


const VideoDetails = ({
  vertical,
  video,
  relatedArticle,
  isChromeless,
  saveHistory,
  savedContentId,
  mparticleId,
  authenticationState,
}) => {
  const [readMoreSelected, setReadMoreSelected] = useState(false);
  const [sponsorMoreSelected, setSponsorMoreSelected] = useState(false);
  const updatedCanonicalPage = getFeatureConfigForBrand(UPDATED_CANONICAL_VIDEO, vertical);
  const renderShareThis = getFeatureConfigForBrand(CANONICAL_VIDEO_SHARE_THIS, vertical);
  const renderSave = getFeatureConfigForBrand(CANONICAL_VIDEO_SAVE, vertical);
  const verticalSlug = getSlugForVertical(vertical);
  const showEmbedIcon = !isSourcePartner(video.source?.roles);
  const socialActions = {
    save: renderSave,
    embed: {
      path: `/${verticalSlug}/embedded-video/${video.id}`,
    },
    pinterest: false,
    copy: false,
    sms: false,
    shareThis: renderShareThis,
  };
  useEffect(() => {
    const updateHistory = async () => {
      if (authenticationState === AUTHENTICATED && savedContentId !== video.id && mparticleId) {
        await saveHistory({ contentId: video.id, contentType: VIDEO });
      }
    };

    updateHistory();
  }, [authenticationState, saveHistory, video.id, savedContentId, mparticleId]);

  const readMoreClick = () => setReadMoreSelected(!readMoreSelected);

  const RenderRelated = () => {
    const associatedContent = video?.associatedContent;
    let content;
    if (associatedContent && !associatedContent.length < 1) {
      content = associatedContent.map((associatedItem) => ({
        headline: associatedItem?.text || associatedItem?.headline.primary,
        url: associatedItem.url.primary,
      }));
    } else if (relatedArticle?.headline && relatedArticle?.url) {
      content = [{
        headline: relatedArticle.headline.primary,
        url: relatedArticle.url,
        ecommerceEnabled: relatedArticle.ecommerceEnabled,
      }];
    } else return null;

    return (
      <div className="video-details__related-article" data-testid="related-container">
        {vertical === 'today' ? <div className="video-details__related-article-title">Mentioned In This Video</div> : null}
        {content.map((associatedItem) => (
          <Link
            href={associatedItem.url}
            icid="canonical_related"
            data-testid="related-link"
            rel="noopener noreferrer"
            target="_blank"
          >
            <p
              className={classNames(
                'video-details__article',
                {
                  'color-secondary': vertical === 'today',
                  'color-knockout-primary f4 lh-copy': vertical !== 'today',
                },
              )}
              data-testid="related-text"
            >
              <span
                className={classNames(
                  'video-details__icon',
                  'icon',
                  {
                    'icon-dot': !associatedItem.ecommerceEnabled,
                    'icon-shop': associatedItem.ecommerceEnabled,
                    'color-primary': vertical === 'today' || vertical === 'msnbc',
                    'color-secondary': vertical === NEWS,
                  },
                )}
              />
              {associatedItem.ecommerceEnabled && (
                <span className="b mr1" data-testid="related-prefix">
                  {i18next.t('SHOP')}
                :
                </span>
              )}
              <span>
                {associatedItem.headline}
              </span>
            </p>
          </Link>
        ))}
      </div>
    );
  };

  const onSponsorMoreClick = () => setSponsorMoreSelected(!sponsorMoreSelected);

  const RenderReadMore = () => {
    const label = readMoreSelected ? i18next.t('Less') : i18next.t('More');

    const className = classNames('video-details__dek-read-more', {
      'video-details__dek-open': readMoreSelected,
    });

    return (
      <button
        className={className}
        onClick={readMoreClick}
        type="button"
      >
        {i18next.t('Read')}
        {' '}
        {label}
      </button>
    );
  };

  const RenderLiveBlinkingDot = () => {
    const isLiveVideo = isVideoLive(video);
    return (
      <>
        {isLiveVideo && (
          <span className="video-details__flag">
            <InlineBlinkingLiveDot className="video-details__flag-dot" />
            {i18next.t('Live')}
            &nbsp;
            {'/ '}
          </span>
        )}
      </>
    );
  };

  const RenderTitle = () => {
    const { headline } = video;
    return (
      <h1 className="video-details__title">
        {RenderLiveBlinkingDot()}
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: headline.primary }}
        />
      </h1>
    );
  };

  const RenderSocials = () => {
    const { headline, url } = video;

    if (updatedCanonicalPage) {
      return (
        <div
          className="video-details__social-container"
          data-testid="video-details__social-container"
        >
          <BookmarkButton contentId={video.id} contentType={VIDEO} pageRegion="video-top" />
          <div className="video-details__social-container__social-share-menu">
            <SocialShareMenu
              url={url.primary}
              headline={headline.tease}
              contentId={video.id}
              pageRegion="video-top"
              trackingEventName={EVENT_NAME}
              actions={socialActions}
              showEmbedIcon={showEmbedIcon}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        className="video-details__social-share-menu"
        data-testid="video-details__social-share-menu"
      >
        <SocialShareMenu
          url={url.primary}
          headline={headline.tease}
          contentId={video.id}
          pageRegion="video-top"
          trackingEventName={EVENT_NAME}
          actions={socialActions}
          showEmbedIcon={showEmbedIcon}
        />
      </div>
    );
  };

  const RenderDek = () => {
    const {
      description,
      datePublished,
      headline,
      url,
    } = video;

    const dekClasses = classNames('video-details__dek', {
      'video-details__dek-open': readMoreSelected,
    });

    const isLiveVideo = isVideoLive(video);
    const isToday = vertical === 'today';

    const socialShareAndDek = (
      <>
        {updatedCanonicalPage && (
          <div
            className="video-details__dek__social-share-menu"
            data-testid="video-details__dek__social-share-menu"
          >
            <SocialShareMenu
              url={url.primary}
              headline={headline.tease}
              contentId={video.id}
              pageRegion="video-top"
              trackingEventName={EVENT_NAME}
              actions={socialActions}
              showEmbedIcon={showEmbedIcon}
            />
          </div>
        )}
        <p className="video-details__dek-text">
          <span className={classNames('video-details__dek-description')}>
            {description.primary}
          </span>
          {!isLiveVideo
            && (
              <span className="video-details__by-line">
                {getFormattedDateString(datePublished, null, {}, i18next)}
              </span>
            )}
        </p>
      </>
    );

    return (
      isToday ? (
        <div className="video-details__dekWrapper">
          {!isChromeless ? RenderRelated() : null}
          <div className={dekClasses}>
            {socialShareAndDek}
          </div>
        </div>
      )
        : (
          <div className={dekClasses}>
            {socialShareAndDek}
            {!isChromeless ? RenderRelated() : null}
          </div>
        )
    );
  };

  const RenderUnibrow = () => {
    const { unibrow } = video;

    return unibrow?.text && (
      <Unibrow
        className="video-details__category"
        unibrow={unibrow}
        enableLogos
      />
    );
  };

  const RenderSponsor = () => {
    const labelText = i18next.t('What\'s this?');
    const labelTitle = i18next.t('Click to see more info');

    return (
      <div
        className={classNames(
          'video-details__category-and-sponsor',
          {
            'video-details__no-sponsor': !video.nativeAd,
          },
          'db-m df flex-column',
        )}
      >
        {!updatedCanonicalPage && RenderUnibrow()}
        {video.nativeAd && (
          <div>
            <section
              className={classNames(
                'video-details__sponsor-top',
                'db-m df justify-start items-center',
              )}
            >
              <div>
                {/* eslint-disable jsx-a11y/no-static-element-interactions,
                  jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid */}
                <a
                  className="video-details__sponsor-more-link"
                  title={labelTitle}
                  onClick={onSponsorMoreClick}
                >
                  {labelText}
                </a>
                {/* eslint-enable jsx-a11y/click-events-have-key-events,
                  jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid */}
              </div>
            </section>
            <div
              className={classNames('video-details__sponsor-more', {
                'video-details__sponsor-more-selected': sponsorMoreSelected,
              })}
            >
              <p>
                {i18next.t(
                  // eslint-disable-next-line max-len
                  'This content was paid for by an advertiser and created by the NBC News Brand Studio. The NBC News editorial organization was not involved in its creation or production. This content represents the views and opinions of the advertiser, who is responsible for all of the material contained therein. Links are not endorsements by NBC News.',
                )}
              </p>
              {/* eslint-disable jsx-a11y/no-static-element-interactions,
                 jsx-a11y/click-events-have-key-events */}
              <span
                className={classNames(
                  'video-details__sponsor-more-close',
                  'icon',
                  'icon-close',
                )}
                onClick={onSponsorMoreClick}
              />
              {/* eslint-disable jsx-a11y/no-static-element-interactions,
                 jsx-a11y/click-events-have-key-events */}
            </div>
          </div>
        )}
      </div>
    );
  };

  const RenderVideoDuration = () => {
    const formattedDuration = formatDuration(video.duration);

    return (
      <span className="video-details__duration" data-testid="videoDetails">{formattedDuration}</span>
    );
  };

  return (
    <div className={classNames({ 'layout-grid-container': !updatedCanonicalPage })}>
      <div
        className={classNames('video-details__info', {
          'video-details__sponsored': video.nativeAd,
        })}
        data-testid="video-details"
      >
        {RenderSponsor()}
        <div className="video-details__title-and-share-and-dek">
          <div className="video-details__title-and-share">
            {RenderTitle()}
            {RenderVideoDuration()}
            {RenderSocials()}
          </div>
          {RenderDek()}
          {RenderReadMore()}
        </div>
      </div>
    </div>
  );
};

VideoDetails.propTypes = {
  vertical: PropTypes.string.isRequired,
  video: CustomPropTypes.video.isRequired,
  relatedArticle: CustomPropTypes.relatedVideoArticle,
  isChromeless: PropTypes.bool.isRequired,
  saveHistory: PropTypes.func.isRequired,
  savedContentId: PropTypes.string,
  mparticleId: PropTypes.string,
  authenticationState: PropTypes.oneOf([
    AUTHENTICATED,
    UNAUTHENTICATED,
    INITIALIZED,
    UNINITIALIZED,
    UNKNOWN,
  ]),
};

VideoDetails.defaultProps = {
  relatedArticle: {},
  savedContentId: null,
  mparticleId: null,
  authenticationState: UNKNOWN,
};

/**
 * Selector for the zustand store.
 * @param {Object} state - The zustand state.
 * @returns {Object} - The props for the component.
 */
const selector = (state) => ({
  saveHistory: state.saveHistory,
  mparticleId: state.customer.mparticleId,
  savedContentId: state.savedContentId,
  authenticationState: state.authenticationState,
});

export default withStore(
  withAccountWorkflows(VideoDetails),
  useMyNewsStore,
  selector,
);
