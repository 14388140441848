import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import * as CustomPropTypes from 'lib/CustomPropTypes';
import BTE from 'lib/BTE';
import Breakpoints from 'lib/Breakpoints';

import LeftRailLayout from 'components/layouts/LeftRail';
import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import LayoutContext from 'components/layouts/LayoutContext';

import styles from './styles.module.scss';

const oneUp = (items) => ({
  id: 'bestOfOneUp',
  name: null,
  type: 'oneUp',
  zone: 2,
  metadata: {},
  autofill: {},
  items,
});

const twoUp = (items) => ({
  id: 'bestOfTwoUp',
  name: null,
  type: 'twoUp',
  zone: 2,
  metadata: {},
  autofill: null,
  items,
});

const threeUp = (items) => ({
  id: 'bestOfThreeUp',
  name: null,
  type: 'threeUp',
  zone: 2,
  metadata: {},
  autofill: null,
  items,
});

class BestOfPlaylist extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    playlist: CustomPropTypes.videoPlaylist.isRequired,
    vertical: PropTypes.string.isRequired,
  };

  static defaultProps = {
    title: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      commonLayout: true,
    };
  }

  componentDidMount() {
    BTE.on('resize', this.monitorBreakpoint);
  }

  componentWillUnmount() {
    BTE.remove('resize', this.monitorBreakpoint);
  }

  monitorBreakpoint = () => {
    const isXL = Breakpoints.isXL();
    const { commonLayout } = this.state;

    if (commonLayout && isXL) {
      this.setState({ commonLayout: false });
    } else if (!commonLayout && !isXL) {
      this.setState({ commonLayout: true });
    }
  };

  render() {
    const {
      title,
      playlist: {
        videos,
        id,
      },
      vertical,
    } = this.props;
    const { commonLayout } = this.state;

    const isMSNBC = vertical === 'msnbc';
    const mbtVideos = videos
      // TODO: remove sort in Bento when API returns sorted playlists
      .sort((a, b) => new Date(b.datePublished) - new Date(a.datePublished))
      // Only process what is needed (playlist can have 100 videos)
      .slice(0, 6)
      // Format
      .map((video) => ({
        type: 'video',
        id: video.id,
        item: {
          ...video,
        },
        metadata: {},
        computedValues: {
          headline: video.headline.primary,
          dek: video.description.primary,
          unibrow: video.unibrow,
          url: video.url.primary,
          teaseImage: {
            ...video.teaseImage,
            url: video.teaseImage.url,
          },
        },
      }));

    const url = `${mbtVideos[0].item.url.primary}?playlist=${id}`;

    const titlePkg = {
      id: 'bestOfTitle',
      name: `${i18next.t('Best Of')} ${vertical[0].toUpperCase() + vertical.slice(1)}`,
      type: 'collectionTitle',
      zone: 1,
      metadata: {
        title,
        seeAllUrl: url,
        seeAllText: i18next.t('Play All'),
      },
    };

    const ad = {
      id: 'bestOfAd',
      name: 'bestOfAd',
      type: 'ad',
      zone: 1,
      metadata: {},
    };

    const packages = [titlePkg, ad];
    if (commonLayout) {
      // TwoUp, ThreeUp, OneUp (all but 1240+)
      packages.push(
        twoUp(mbtVideos.slice(0, 2)),
        threeUp(mbtVideos.slice(2, 5)),
        oneUp([mbtVideos[5]]),
      );
    } else {
      // TwoUp, OneUp, ThreeUp (1240+)
      packages.push(
        twoUp(mbtVideos.slice(0, 2)),
        oneUp([mbtVideos[2]]),
        threeUp(mbtVideos.slice(3)),
      );
    }

    return (
      <div
        className="layout-grid-container mv8 best-of-playlist"
        data-testid="best-of-playlist"
      >
        <LayoutContext
          layoutType={null}
          vertical={vertical}
        >
          <LeftRailLayout packages={packages} />
        </LayoutContext>
        <ButtonHoverAnimation
          additionalClasses={styles.bottomButton}
          hasDarkBackground={isMSNBC}
          title="Play All"
          type="link"
          url={url}
        />
      </div>
    );
  }
}

export default BestOfPlaylist;
