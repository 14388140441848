import React, { Component } from 'react';
import i18next from 'i18next';

import { Picture } from 'components/Picture';
import Link from 'components/Link';

import buildUrl from 'lib/buildUrl';
import { imageAltForImage } from 'lib/imageAlt';
import playlistType from 'lib/CustomPropTypes/videoPlaylist';
import isVerticalVideo from 'lib/isVerticalVideo';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import styles from './styles.module.scss';

export const playlistUrl = (video, playlistId) => {
  if (isVerticalVideo(video)) {
    return `${buildUrl({ ...video, type: 'video' })}?playlist=${playlistId}`;
  }

  return video.url.primary;
};

class Playlist extends Component {
  static propTypes = {
    content: playlistType.isRequired,
  };

  playlistInfo() {
    const { content: { headline, videos, id } } = this.props;
    const count = `${videos.length} ${i18next.t('Videos')}`;
    const url = playlistUrl(videos[0], id);
    const sponsor = null;

    return (
      <div className={styles.info}>
        <Link to={url}>
          <h2 className={styles.title}>{ headline.primary }</h2>
          <div className={styles.count}>{ count }</div>
          <div className={styles.sponsor}>{ sponsor }</div>
        </Link>
      </div>
    );
  }

  playlistImage(idx, sizes) {
    const { content: { id, videos } } = this.props;

    if (!videos || !videos[idx]) {
      return null;
    }

    const videoUrl = playlistUrl(videos[idx], id);

    return (
      <div key={`image_${idx}`} className={styles.image}>
        <a href={videoUrl}>
          <Picture
            sizes={sizes}
            url={videos[idx].teaseImage.url.primary}
            alt={imageAltForImage(videos[idx].teaseImage)}
          />
        </a>
      </div>
    );
  }

  playlistImages() {
    const sizesPrimary = {
      s: AIMS_FLAVORS.FOCAL_720x360,
      m: AIMS_FLAVORS.FOCAL_564x282,
      l: AIMS_FLAVORS.FOCAL_460x230,
    };
    const sizesSecondary = {
      s: AIMS_FLAVORS.FOCAL_360x180,
      m: AIMS_FLAVORS.FOCAL_282x141,
      l: AIMS_FLAVORS.FOCAL_220x110,
    };

    return (
      <div className={styles.images}>
        { this.playlistImage(0, sizesPrimary) }
        { this.playlistImage(1, sizesSecondary) }
        { this.playlistImage(2, sizesSecondary) }
      </div>
    );
  }

  render() {
    return (
      <section
        className={styles.playlist}
      >
        { this.playlistInfo() }
        { this.playlistImages() }
      </section>
    );
  }
}

export default Playlist;
