export function formatPlaylistDataForBacon(playlist, overwrites = {}) {
  return {
    type: 'bacon',
    cardType: 'twoOneLargeVideoPage',
    context: { pageType: 'video' },
    metadata: {
      title: Array.isArray(playlist.headline.primary) ? playlist.headline.primary.join('') : playlist.headline.primary || '',
    },
    items: playlist.videos.map((video) => ({
      type: 'video',
      id: video.id,
      item: video,
      computedValues: {
        headline: (video.headline?.primary) || '',
        url: (video.url?.primary) || '',
        teaseImage: video.teaseImage || {},
        alternateTeaseImages: video.alternateTeaseImages || [],
      },
    })),
    ...overwrites,
  };
}
