import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import playlistType from 'lib/CustomPropTypes/videoPlaylist';
import Playlist from './Playlist';

import './styles.themed.scss';

function Playlister({ playlists }) {
  if (!playlists.length) {
    return null;
  }

  return (
    <section className="playlister layout-grid-container">
      <div className="playlister__content layout-grid-item grid-col-12">
        <h2 className="playlister__title">{ i18next.t('Watch More') }</h2>
        <div className="playlister__playlists">
          { playlists.map(
            (playlist) => <Playlist key={`playlist-${playlist.id}`} content={playlist} />,
          ) }
        </div>
      </div>
    </section>
  );
}

Playlister.propTypes = {
  playlists: PropTypes.arrayOf(playlistType),
};

Playlister.defaultProps = {
  playlists: [],
};

export default Playlister;
